import React from 'react'
import Section from '../components/Section'
import FooterLink from './FooterLink'
import SectionWrapper from '../components/SectionWrapper'

const Footer = () => (
  <footer id="site-footer" className="page-footer d-lg-block">
    <Section className="bg-level-2 px-3 pb-3">
      <SectionWrapper className="with-margin pb-0">
        <div className="container px-0">
          <div className="row">
            <div className="col-6 col-md-3 mb-3">
              <small className="medium-strong">Unser Angebot</small>
              <ul className="p-0 m-0">
                <li>
                  <FooterLink to={'/termine'} tite={'Termine'}/>
                </li>
                <li>
                  <FooterLink to={'/architektur'} tite={'Architektur'}/>
                </li>
                <li>
                  <FooterLink to={'/web'} tite={'Web'}/>
                </li>
                <li>
                  <FooterLink to={'/enterprise-java'} tite={'Enterprise Java'}/>
                </li>
                <li>
                  <FooterLink to={'/jqassistant'} tite={'jQAssistant'}/>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <small className="medium-strong">Referenzen</small>
              <ul className="p-0 m-0">
                <li>
                  <FooterLink to={'/referenzen/arbeitsagentur'} tite={'Bundesagentur für Arbeit'}/>
                </li>
                <li>
                  <FooterLink to={'/referenzen/one-data'} tite={'One Data GmbH'}/>
                </li>
                <li>
                  <FooterLink to={'/referenzen/epost-jqa'} tite={'E-Post Development GmbH'}/>
                </li>
                <li>
                  <FooterLink to={'/referenzen/tks-fls'} tite={'Thyssen Krupp Steel AG'}/>
                </li>
                <li>
                  <FooterLink to={'/referenzen/interdiscount-probot'} tite={'Interdiscount'}/>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <small className="medium-strong">Karriere</small>
              <ul className="p-0 m-0">
                <li>
                  <FooterLink to={'/karriere'} tite={'Auf zu neuen Ufern'}/>
                </li>
                <li>
                  <FooterLink to={'/karriere/#StellenangeboteAktuell'} tite={'Stellenangebote'}/>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-3 mb-3">
              <small className="medium-strong">Über BUSCHMAIS</small>
              <ul className="p-0 m-0">
                <li>
                  <FooterLink to={'/unternehmen'} tite={'Unternehmen'} />
                </li>
                <li>
                  <FooterLink to={'/unternehmen/kooperationen'} tite={'Kooperationen'} />
                </li>
                <li>
                  <FooterLink to={'/unternehmen/community'} tite={'Community'} />
                </li>
                <li>
                  <FooterLink to={'/unternehmen/innovation'} tite={'Innovation'} />
                </li>
                <li>
                  <FooterLink to={'/unternehmen/publikationen'} tite={'Publikationen'} />
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col">
            <hr/>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <small className="text-muted">
                Copyright - BUSCHMAIS GbR. {new Date().getFullYear()}, Inhaber: Torsten Busch, Frank Schwarz, Dirk
                Mahler
                und Tobias Israel. Alle Rechte vorbehalten.
              </small>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <FooterLink to="/legal/datenschutz" tite={'Datenschutz'}/>
              <span> | </span>
              <FooterLink to="/legal/impressum" tite={'Impressum'}/>
              <span> | </span>
              <FooterLink to="/legal/rechtliche-hinweise" tite={'Rechtliche Hinweise'}/>
              <span> | </span>
              <FooterLink to="/legal/teilnahmebedingungen" tite={'Teilnahmebedingungen'}/>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </Section>
  </footer>
)

export default Footer
