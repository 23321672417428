import { createStore as reduxCreateStore } from 'redux'
import * as constants from '../common/constants'

const reducer = (state, action) => {
  const { innerWidth: width } = constants.windowGlobal
  if (action.type === `UPDATE_MENU_STYLE`) {
    if (state.menuOpen) {
      return Object.assign({}, state, {
        menuInverted: width <= constants.BREAKEPOINT_MD_UP ? false : action.payload.menuInverted,
        menuInvertedBak: action.payload.menuInverted,
        logoInvertedBak: action.payload.logoInverted,
      })
    } else {
      return Object.assign({}, state, {
        menuInverted: action.payload.menuInverted,
        logoInverted: action.payload.logoInverted,
      })
    }
  } else if (action.type === 'TOGGLE_MENU') {
    if (state.menuOpen) {
      return Object.assign({}, state, {
        menuOpen: false,
        menuInverted: state.menuInvertedBak,
        logoInverted: state.logoInvertedBak,
      })
    } else {
      return Object.assign({}, state, {
        menuOpen: true,
        menuInverted: width <= constants.BREAKEPOINT_MD_UP ? false : state.menuInverted,
        logoInverted: false,
        menuInvertedBak: state.menuInverted,
        logoInvertedBak: state.logoInverted,
      })
    }
  }
  return state
}

const initialState = {
  menuOpen: false,
  menuInverted: false,
  logoInverted: false,
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore