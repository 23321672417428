import React from 'react'
import * as PropTypes from 'prop-types'

class ScrollSpy extends React.Component {

  componentDidMount() {
    window.addEventListener('scroll', this.props.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.props.handleScroll)
  }

  render() {
    let { children } = this.props
    return children
  }
}

ScrollSpy.propTypes = {
  handleScroll: PropTypes.func,
  children: PropTypes.node,
}

export default ScrollSpy