import React from 'react'
import Helmet from 'react-helmet'
import '../assets/fonts/glacial/stylesheet.css'
import '../assets/fonts/noto-sans-v8-latin/stylesheet.css'
import '../assets/fonts/opensans/stylesheet.css'
import '../assets/fonts/roboto-condensed-v17-latin/stylesheet.css'
import '../assets/fonts/icomoon-v1.0/stylesheet.css'
import '../assets/scss/global.scss'
import '../assets/scss/style-default.scss'
import Menu from './Menu'
import PageCover from './PageCover'
import ContentArea from './ContentArea'
import SocialMediaFooter from './SocialMediaFooter'
import Footer from './Footer'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  const { menuOpen } = state
  return { menuOpen, ...ownProps }
}

const Index = (props) => {
  return (
    <React.Fragment>
      <Helmet bodyAttributes={{
        class: props.menuOpen ? 'body-page menu-visible' : 'body-page',
      }}/>
      <Menu/>
      <PageCover menuOpen={props.menuOpen}/>
      <ContentArea>
        {props.children}
      </ContentArea>
      <Footer/>
      <SocialMediaFooter/>
    </React.Fragment>
  )
}

export default connect(mapStateToProps, null)(Index)
