import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const FooterLink = ({ to, tite }) => (
  <Link to={to} className="footer-link">
    <small>{tite}</small>
  </Link>
)

export default FooterLink

FooterLink.propTypes = {
  tite: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
