import React from 'react'

const SocialMediaFooter = () => (
  <footer id="social-media-footer" className="page-footer d-none d-lg-block">
    <div className="footer-left">
      <ul className="social">
        <li>
          <a href="https://twitter.com/buschmais" target="_blank" rel="noopener noreferrer"
             style={{ textDecoration: 'none' }}
             aria-label="Besuchen Sie unsere Präsenz auf X">
            <i className="icon icon-x"/>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/buschmais" target="_blank" rel="noopener noreferrer"
             style={{ textDecoration: 'none' }}
             aria-label="Besuchen Sie unsere Präsenz auf Instagram">
            <i className="icon icon-instagram"/>
          </a>
        </li>
        <li>
          <a href="https://vimeo.com/buschmais" target="_blank" rel="noopener noreferrer"
             style={{ textDecoration: 'none' }}
             aria-label="Besuchen Sie unsere Präsenz auf Vimeo">
            <i className="icon icon-vimeo"/>
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default SocialMediaFooter
