import * as PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'


const Section = ({ className, id, children }) => (
  <div className={classNames('section', className)} id={id ? id : 'section-' + Math.random()}>{children}</div>
)

export default Section

Section.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
}
