import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const MenuItem = ({ url, title, onClick }) => (
  <li className="nav-item pb-0 pb-lg-2">
    <Link to={url} className="nav-link" onClick={onClick}><span>{title}</span></Link>
  </li>
)

MenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export default MenuItem
