import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import logoImg from '../assets/img/logo.svg'
import logoImgInverted from '../assets/img/logo-inverted.svg'
import classNames from 'classnames'
import MenuItem from './MenuItem'
import { connect } from 'react-redux'
import ScrollSpy from '../components/ScrollSpy'

const mapStateToProps = (state, ownProps) => {
  const { menuOpen, menuInverted, logoInverted } = state
  return { menuOpen, menuInverted, logoInverted, ...ownProps }
}

class Menu extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      menuReachesScrollBreakPoint: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.handleMenuToggleClicked = this.handleMenuToggleClicked.bind(this)
  }

  handleMenuToggleClicked = () => {
    this.props.dispatch({ type: 'TOGGLE_MENU' })
  }

  handleScroll = () => {
    const breakpointReached = window.pageYOffset > 40
    if (breakpointReached !== this.state.menuReachesScrollBreakPoint) {
      this.setState(() => ({ menuReachesScrollBreakPoint: breakpointReached }))
    }
  }

  handleResize = () => {
    this.props.dispatch({
      type: 'UPDATE_MENU_STYLE',
      payload: { menuInverted: this.props.menuInverted, logoInverted: this.props.logoInverted },
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    let { menuInverted, logoInverted, menuOpen } = this.props
    return (
      <ScrollSpy handleScroll={this.handleScroll}>
        <header
          className={classNames('page-header navbar page-header-drawer menu-right', {
            'scrolled': this.state.menuReachesScrollBreakPoint && !menuOpen,
            'content-white': menuInverted,
          })}>

          <div className="menu-btn-wrapper"/>
          <button aria-label={'Seitenmenü öffnen/schließen'} className={classNames('navbar-toggler site-menu-icon', {
            'menu-visible': menuOpen,
          })} onClick={this.handleMenuToggleClicked}>
          <span className={classNames('menu-icon menu-icon-line', { 'menu-visible': menuOpen })}>
            <span className={classNames('text show-menu-visible', {
              'text-white': menuInverted,
            })}>Schließen</span>
            <span className="text hide-menu-visible">Menü</span>
            <span className="bars">
              <span className="bar1"/>
              <span className="bar2"/>
              <span className="bar3"/>
            </span>
          </span>
          </button>

          <a className="navbar-brand" href="/">
            <span className={classNames('logo', { 'd-none d-md-block': menuOpen })}>
              <img className="d-none d-md-block" src={logoInverted ? logoImgInverted : logoImg} alt="Logo"/>
              <img className="d-md-none" src={logoImg} alt="Logo"/>
            </span>
          </a>

          <div className={classNames('all-menu-wrapper', { 'menu-visible': menuOpen })}>
            <nav className="navbar-mainmenu">
              <div className="mainmenu-bg" onClick={this.handleMenuToggleClicked} tabIndex="0"
                   onKeyPress={this.handleMenuToggleClicked} role="button" aria-label="Navigationsmenü öffnen"/>
              <div className="mainmenu-content d-flex flex-column justify-content-between">
                <div className="d-flex flex-grow-1 flex-column justify-content-center">
                  <ul className="navbar-nav">
                    <MenuItem url="/termine/" title="Termine" onClick={this.handleMenuToggleClicked}
                              id="mi-termine/"/>
                    <MenuItem url="/architektur/" title="Architektur" onClick={this.handleMenuToggleClicked}
                              id="mi-architecture"/>
                    <MenuItem url="/web/" title="Web" onClick={this.handleMenuToggleClicked} id="mi-web"/>
                    <MenuItem url="/enterprise-java/" title="Enterprise Java" onClick={this.handleMenuToggleClicked}
                              id="mi-enterprise-java"/>
                    <MenuItem url="/jqassistant/" title="jQAssistant" onClick={this.handleMenuToggleClicked}
                              id="mi-jQAssistant"/>
                    <MenuItem url="/karriere/" title="Karriere" onClick={this.handleMenuToggleClicked} id="mi-jobs"/>
                    <MenuItem url="/unternehmen/" title="Unternehmen" onClick={this.handleMenuToggleClicked}
                              id="mi-about"/>
                  </ul>
                </div>
                <div className="navbar-footer w-100 d-flex justify-content-between align-items-center pb-5 pb-sm-0">
                  <div className="icons icons-margin hidden-xs">
                    <a className="btn-icon" href="https://vimeo.com/buschmais" target="_blank"
                       rel="noopener noreferrer" style={{ textDecoration: 'none' }}
                       aria-label="Besuchen Sie unsere Präsenz auf Vimeo.">
                      <i className="icon icon-vimeo"/>
                    </a>
                    <a className="btn-icon" href="https://www.instagram.com/buschmais" target="_blank"
                       rel="noopener noreferrer" style={{ textDecoration: 'none' }}
                       aria-label="Besuchen Sie unsere Präsenz auf Instagram.">
                      <i className="icon icon-instagram"/>
                    </a>
                    <a className="btn-icon" href="https://twitter.com/buschmais" target="_blank"
                       rel="noopener noreferrer" style={{ textDecoration: 'none' }}
                       aria-label="Besuchen Sie unsere Präsenz auf Twitter.">
                      <i className="icon icon-x"/>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </ScrollSpy>
    )
  }
}

export default connect(mapStateToProps)(Menu)

Menu.propTypes = {
  handleMenuToggleClicked: PropTypes.func,
  menuOpen: PropTypes.bool,
}
