import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const PageCover = ({ menuOpen }) => (
  <div className={classNames('page-cover', { 'menu-open': menuOpen })}/>
)

export default PageCover

PageCover.propTypes = {
  menuOpen: PropTypes.bool,
}